import { converter } from "./common";
const adjustDateValues = function (data, fields) {
  if (Array.isArray(data) && Array.isArray(fields)) {
    data.forEach((item) => {
      fields.forEach((field) => {
        item[field] = new Date(item[field]);
      });
    });
  }
};
const adjustShipment = function (entity) {
  var adjustedEntity = entity || {};
  if (adjustedEntity.departure) {
    adjustedEntity.departure = new Date(adjustedEntity.departure);
  }
  if (typeof adjustedEntity.arrivedDate == 'string') {
    adjustedEntity.arrivedDate = new Date(adjustedEntity.arrivedDate);
  }
  if (adjustedEntity.weightLbs) {
    adjustedEntity.weightKg = converter.tokg(entity.weightLbs);
  }
  if (Array.isArray(entity.packages)) {
    adjustedEntity.storedPieces = entity.packages.reduce((storedPieces, pkg) => {
      if (pkg.processStatus >= 3) {
        storedPieces += 1;
      }
      return storedPieces;
    }, 0);
    adjustedEntity.deliveredPieces = entity.packages.reduce((deliveredPieces, pkg) => {
      if (pkg.processStatus >= 5) {
        deliveredPieces += 1;
      }
      return deliveredPieces;
    }, 0)
    adjustedEntity.inventory =  adjustedEntity.storedPieces - adjustedEntity.deliveredPieces;
  }
  return adjustedEntity;
};
const adjustPackage = function (entity) {
  entity.weightKg = converter.tokg(entity.weightLbs);
  entity.actualWeightLbs = converter.tolbs(entity.actualWeightKg);
  return entity;
};
export default {
  adjustDateValues,
  adjustShipment,
  adjustPackage,
};
