const mixin = {
  methods: {
    onSendShipment(entity) {
      let error = "";
      if (!entity.departure) {
        error = this.$t("shipment.missing_departure_date");
      } else if (!entity.awbCode) {
        error = this.$t("shipment.missing_awb_code");
      }
      if (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 3000,
        });
      } else {
        this.$confirm.require({
          message: this.$t("shipment.confirm_start"),
          header: this.$t("common.confirmation"),
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this._sendShipment(entity);
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
        });
      }
    },
    _sendShipment(entity) {
      this.service
        .startShipment(entity.id)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: this.$t("shipment.confirmed"),
            life: 3000,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};

export default mixin;
