const preview = {
  methods: {
    openPreview(urlLocation) {
//       let defaultName = "_blank",
//         defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
//         defaultReplace = false;
//        const url = window.location.origin + '/#/print/' + routeName;
        window.open(urlLocation);  
    },
  },
};

export default preview;